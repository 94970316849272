/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import cn from 'classnames';

import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import { Responsive, Header } from 'client/components/common';
import FirstScreen from 'client/components/common/Screens/FirstScreen';
// import ContentForm from 'client/components/common/ContentForm';

import bg from 'assets/client/images/bg.jpg';
import bgMobile from 'assets/client/images/bg-mobile.jpg';

import ItemPopUp from '../../common/ItemPopUp';

import * as css from './Intro.scss';

type Props = {|
	...EnhancedProps,
	className?: string,
	in: boolean,
	leads: Leads,
|};

type State = {
	isShowAlreadyInSystemPopup: boolean,
};
class Intro extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	static defaultProps = {
		className: '',
	};

	componentDidMount() {}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	render() {
		const { className, transitionState, leads } = this.props;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);

		return (
			<div
				className={cn(css.intro, css[transitionState], className)}
				style={{
					backgroundImage: `url(${isMobile ? bgMobile : bg})`,
				}}
			>
				<Header isMobile={isMobile} />
				<FirstScreen leads={leads} />
				<ItemPopUp />
			</div>
		);
	}
}

export default withTransition(Intro);
