import _ from 'lodash';
import firebase from 'firebase/app';
import 'firebase/storage';

import { parseURL } from 'common/utils/parse-url';
import { ORIGINAL_IMAGE_IDENTIFIER, APP_CONFIG } from 'common/constants';
import * as actions from 'client/constants/actions';

export function uploadMedia(file, options) {
	return async dispatch => {
		dispatch({ type: actions.UPLOAD_MEDIA, payload: { file, options } });
		return uploadMediaDB(file, options);
	};
}

export function deleteMedia(file) {
	return async dispatch => {
		dispatch({ type: actions.DELETE_MEDIA, payload: file });
		return deleteMediaDB(file);
	};
}

/**
 * @param file {*}
 * @param options {object}
 * @param options.isStatic {boolean}
 * @param options.extension {string}
 * @param options.filename {string}
 * @param options.name {string}
 * @returns {Promise<{name: *, url: string} | void>}
 */
const uploadMediaDB = async (file, options = {}) => {
	const ref = firebase.storage().ref();
	let originalFileName;
	let { name } = options;

	if (!name) {
		if (options.isStatic) {
			// upload static images, e.g. "share image".
			const extension = options.extension || _.last(file.name.split('.'));
			name = `static/${`${options.filename}.${extension}`}`;
		} else if (file.type.includes('video')) {
			name = `videos/${Date.now()}-${file.name}`;
		} else {
			originalFileName = file.name.replace(/\.(?!.*\.)/, `${ORIGINAL_IMAGE_IDENTIFIER}.`);
			name = `static/${Date.now()}-${originalFileName}`;
		}
	}

	const metadata = {
		contentType: file.type,
	};
	const task = ref.child(name).put(file, metadata);
	return task
		.then(snapshot => snapshot.ref.getDownloadURL())
		.then(url => {
			// const { pathname, search } = parseURL(url);
			// const fileName = _.last(pathname.split('/'));
			// const CDN_URL = `${APP_CONFIG.cdn.hostname}/${fileName}${search}`;
			const { pathname, search } = parseURL(url);
			const CDN_URL = `${APP_CONFIG.cdn.hostname}${pathname}${search}`;
			// const { pathname } = parseURL(url);
			// const fileName = _.last(pathname.split('/'));
			// const CDN_URL = `${APP_CONFIG.cdn.hostname}/${fileName}`;
			return {
				url: CDN_URL,
				name,
			};
		})
		.catch(console.error);
};

const deleteMediaDB = async name => {
	const ref = firebase.storage().ref();
	const task = ref.child(name).delete();

	return task.then(({ ...args }) => console.info('deleteMedia %O', { name, args })).catch(console.error);
};
