import { CAN_USE_DOM } from '../constants';

export const dataLayerEvents = {
	/**
	 * @param label {String}
	 */
	onShareBtnClick: label =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'Memorial Day',
			Action: 'Share Memorial Main Page',
			Label: label,
		}),
	onSharePersonalBtnClick: label =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'Memorial Day',
			Action: 'Share Personal Story',
			Label: label,
		}),
	onFormSended: () =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'Memorial Day',
			Action: 'Write Memory',
			Label: 'Send',
		}),
	onMenuItemClick: label =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'Memorial Day',
			Action: 'Side Bar',
			Label: label,
		}),
	onCreateLeadClick: () =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'Memorial Day',
			Action: 'כתבו מילות זיכרון',
			Label: '',
		}),
	onBackClick: () =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'Memorial Day',
			Action: 'Back to Main Page',
			Label: '',
		}),
	onSearch: () =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'Memorial Day',
			Action: 'Search Story',
			Label: '',
		}),
};
