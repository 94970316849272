/* eslint-disable react/no-did-update-set-state */
// @flow
import React from 'react';
import Autosuggest from 'react-autosuggest';
import _ from 'lodash';
import { dataLayerEvents } from 'common/utils/gtag';

import searchIcon from 'assets/client/images/search-icon.png';
import css from './Search.scss';

type Props = {
	leads: any,
	setFilteredItems: any,
	setIsSearch: any,
	setIsLoading: any,
	setIsNoResults: any,
	isLoading: boolean,
	isSearch: boolean,
};
type State = {
	value: string,
	suggestions: [],
};

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export default class Search extends React.PureComponent<Props, State> {
	constructor() {
		super();

		this.state = {
			value: '',
			suggestions: [],
		};
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.isSearch !== this.props.isSearch && !this.props.isSearch) {
			this.setState({
				value: '',
			});
		}
	}

	renderInputComponent = (inputProps: any) => (
		<div className={css.inputContainer}>
			<img
				className={css.icon}
				src={searchIcon}
				alt=""
				onClick={e => this.suggestionSelected(e, this.state.value, true)}
			/>
			<input {...inputProps} />
		</div>
	);

	renderSuggestionsContainer = ({ containerProps, children, query }: any) => (
		<div {...containerProps}>
			{children}
			{/* {
				<div className="footer">
					Press Enter to search <strong>{query}</strong>
				</div>
			} */}
		</div>
	);

	renderSuggestion = (suggestion: LeadInfo) => suggestion.nameOfTheCandle;

	getSuggestionValue = (suggestion: LeadInfo) => suggestion.nameOfTheCandle;

	onChange = (event: any, { newValue, method }: any) => {
		this.setState({
			value: newValue,
		});
	};

	onSuggestionsFetchRequested = ({ value }: any) => {
		this.setState({
			suggestions: this.getSuggestions(value),
		});
	};

	getSuggestions = (value: any) => {
		const { leads } = this.props;
		const escapedValue = escapeRegexCharacters(value.trim());

		if (escapedValue === '') {
			return [];
		}

		const regex = new RegExp(`^${escapedValue}`, 'i');
		return leads.filter(lead => regex.test(lead.nameOfTheCandle));
	};

	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: [],
		});
	};

	suggestionSelected = (e: any, { suggestionValue }: any, isSearchClick: boolean) => {
		const { leads, setFilteredItems, setIsLoading } = this.props;
		this.props.setIsNoResults(false);

		setIsLoading(true);
		if (isSearchClick) {
			this.props.setIsNoResults(false);

			setTimeout(() => {
				const arrFilteredLeads = [];
				_.forEach(leads, item => {
					if (_.includes(item.nameOfTheCandle.toLowerCase(), this.state.value.toLowerCase())) {
						arrFilteredLeads.push(item);
					}
				});

				setFilteredItems(arrFilteredLeads);
				this.props.setIsSearch(true);
				setIsLoading(false);
			}, 1300);
		} else {
			setTimeout(() => {
				this.props.setIsNoResults(false);

				const leadsFilter = _.filter(leads, ['nameOfTheCandle', suggestionValue]);
				setFilteredItems(leadsFilter);
				this.props.setIsSearch(true);
				setIsLoading(false);
			}, 1300);
		}
		dataLayerEvents.onSearch();
	};

	onKeyDown = (e: any) => {
		const { leads, setFilteredItems, setIsLoading, isLoading, setIsNoResults } = this.props;

		if (e.keyCode === 13) {
			setIsNoResults(false);

			if (!isLoading) {
				dataLayerEvents.onSearch();
			}
			setIsLoading(true);

			setTimeout(() => {
				const arrFilteredLeads = [];

				// Search by name
				_.forEach(leads, item => {
					if (_.includes(item.nameOfTheCandle.toLowerCase(), this.state.value.toLowerCase())) {
						arrFilteredLeads.push(item);
					}
				});

				// If no search items we show no results
				if (_.size(arrFilteredLeads) === 0) {
					setIsNoResults(true);
				}

				setFilteredItems(arrFilteredLeads);
				this.props.setIsSearch(true);
				setIsLoading(false);
			}, 1300);
		}
	};

	render() {
		const { value, suggestions } = this.state;
		const inputProps = {
			placeholder: 'חפשו מילות זיכרון לפי שם הנופל',
			value,
			onChange: this.onChange,
			onKeyDown: this.onKeyDown,
		};

		return (
			<Autosuggest
				suggestions={suggestions}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				getSuggestionValue={this.getSuggestionValue}
				renderSuggestion={this.renderSuggestion}
				inputProps={inputProps}
				renderSuggestionsContainer={this.renderSuggestionsContainer}
				renderInputComponent={this.renderInputComponent}
				onSuggestionSelected={this.suggestionSelected}
			/>
		);
	}
}
