// @flow

/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { change as rrChange } from 'redux-form';
import classNames from 'classnames';
import { notification } from 'antd';
import _ from 'lodash';

import * as actions from 'client/actions';
import { CAN_USE_DOM } from 'common/constants';
// import { FILE_DEFAULT } from 'admin/constants';

import uploadIcon from 'assets/client/images/firstScreen/upload.png';
import checkIcon from 'assets/client/images/createLeadPopUp/check.png';
import * as css from './MultipleMediaField.scss';

const REMOTE_LINK_MODE = 'remoteURL';
const UPLOADED_FILE_MODE = 'file';

const IMAGE_FILE_TYPE = 'IMAGE_FILE_TYPE';
const VIDEO_FILE_TYPE = 'VIDEO_FILE_TYPE';
const AUDIO_FILE_TYPE = 'AUDIO_FILE_TYPE';

type Props = {
	className?: string,
	fileType?: string,
	sizeLimit?: number,
	videoSizeLimit?: number,
	preview?: string,
	name?: string,
	input: ReduxFormInput,
	meta: ReduxFormMeta,
	placeholder?: any,
	disabled?: boolean,
	onlyFileUpload?: boolean,
	changeField: Function,
	uploadMedia: Function,
	onSetFileSize?: Function,
	onShowSizeLimitPopup?: Function,
};

const Media = ({
	className,
	placeholder,
	onlyFileUpload,
	name,
	input,
	meta,
	preview,
	disabled,
	fileType,
	sizeLimit,
	videoSizeLimit,
	uploadMedia,
	changeField,
	onSetFileSize,
	onShowSizeLimitPopup,
}: Props) => {
	const [isActive, setIsActive] = useState(false);
	const [isInProgress, setIsInProgress] = useState(false);
	const [outOfLimitSize, setOutOfLimitSize] = useState(false);
	const [isManyFiles, setIsManyFiles] = useState(false);
	const [files, setFiles] = useState([]);
	// const [files, setFiles] = useState([]);
	const containerRef = useRef<?HTMLDivElement>(null);
	const id = `${meta.form}${input.name}`;
	const inputRef = useRef<?HTMLInputElement>(null);

	const fileSizeErrorMessage = `יש לצרף תמונה בגודל של עד 5 מגה`;
	const fileLimitMessage = `מקסימום 4 קבצים`;

	const onFileChange = async e => {
		setOutOfLimitSize(false);
		setIsManyFiles(false);
		try {
			// let val = {};
			const uploadedFiles = [...files];
			const filesCurrentTotal = _.size(e.target.files) + _.size(files);

			setIsInProgress(true);
			if (filesCurrentTotal < 5) {
				Promise.all(
					_.map(Array.from(e.target.files), async item => {
						const limitToUse = (item.type.includes('video') ? videoSizeLimit : sizeLimit) || 0;
						const sizeLimitError = `File exceeded size limit of ${limitToUse / (1024 * 1024)} Mb`;
						const outLimit = item.size >= limitToUse;

						if (outLimit) {
							console.warn(`${sizeLimitError}\nFile size:${item.size}`);
							setTimeout(() => setOutOfLimitSize(true), 500);

							return;
						}
						await uploadMedia(item).then(res => {
							uploadedFiles.push(res);
							setFiles(prevState => [...prevState, res]);
						});
					})
				)
					.then(() => {
						// val = {
						// 	...FILE_DEFAULT,
						// 	file: uploadedFiles,
						// 	selected: UPLOADED_FILE_MODE,
						// };

						changeField(meta.form, input.name, uploadedFiles);
					})
					.then(() => setIsInProgress(false));
			} else {
				console.info('Too much files, can upload only 4');
				setIsManyFiles(true);
				setIsInProgress(false);
			}
		} catch (error) {
			notification.error({
				message: 'Error!',
				description: error.message || 'Something went wrong while uploading file',
			});
			console.error(error);
		}
	};

	const onDocumentClickListener = (e: MouseEvent) => {
		const target: ?Node = (e.target: any);
		if (containerRef.current && containerRef.current.contains(target)) {
			return;
		}

		setIsActive(false);
	};

	// const onRemoteLinkKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
	// 	if (e.keyCode === 13) {
	// 		e.preventDefault();

	// 		const val = {
	// 			...FILE_DEFAULT,
	// 			selected: REMOTE_LINK_MODE,
	// 			remoteURL: e.currentTarget.value,
	// 		};

	// 		changeField(meta.form, input.name, val);

	// 		e.currentTarget.value = '';

	// 		setIsActive(false);
	// 	}
	// };

	// const onClearClick = () => {
	// 	changeField(meta.form, input.name, FILE_DEFAULT);
	// };

	const onInputClick = e => {
		// e.target.value = '';
		// setFiles([]);
		console.info('on input click');
		// changeField(meta.form, input.name, FILE_DEFAULT);
	};

	const onDeleteImage = (nameFile: string) => {
		const filteredFiles = _.remove([...input.value], (item: any) => item.name !== nameFile);
		setFiles(filteredFiles);

		// const fileIndex = _.findIndex(input.value.file, ['name', nameFile]);
		// const clearItem = {
		// 	url: '',
		// 	name: '',
		// };
		// const newInputValue = [...input.value.file];
		// newInputValue[fileIndex] = clearItem;
		// inputFile[fileIndex].name = '';
		// inputFile[fileIndex].url = '';

		// const val = {
		// 	...FILE_DEFAULT,
		// 	file: newInputValue,
		// 	selected: UPLOADED_FILE_MODE,
		// };
		changeField(meta.form, input.name, filteredFiles);
		inputRef.current.click();
	};

	useLayoutEffect(() => {
		if (CAN_USE_DOM) {
			document.addEventListener('click', onDocumentClickListener);
		}
		return () => {
			if (CAN_USE_DOM) {
				document.removeEventListener('click', onDocumentClickListener);
			}
		};
	});

	return (
		<div
			className={classNames(css.mediaField, className, {
				[css.active]: isActive,
				[css.inProgress]: isInProgress,
				[css.disabled]: disabled,
			})}
			ref={containerRef}
		>
			<div className={classNames(css.fileOutOfLimit, outOfLimitSize && css.fileOutOfLimitActive)}>
				{fileSizeErrorMessage}
			</div>
			<div className={classNames(css.fileOutOfLimit, isManyFiles && css.fileOutOfLimitActive)}>
				{fileLimitMessage}
			</div>
			<label className={classNames(css.controlLabel)} htmlFor={id}>
				<div className={css.fileNameWrapper}>
					<div className={css.text}>{name}</div>
					<div className={css.buttonPlus}>
						<img src={uploadIcon} alt="Upload" />
					</div>
				</div>
				{!name && <div className={css.placeholder}>{placeholder}</div>}
				<input
					id={id}
					type="file"
					multiple
					onChange={onFileChange}
					onClick={e => onInputClick(e)}
					ref={inputRef}
				/>
			</label>
			<div className={classNames(css.uploadedFiles, (outOfLimitSize || isManyFiles) && css.error)}>
				{_.map(files, (item, index: number) => {
					return (
						<div key={`${item.name}|${index}`}>
							{item.name && item.url && (
								<button className={css.file} type="button" onClick={() => onDeleteImage(item.name)}>
									{item.name && item.url && (
										<>
											<img src={checkIcon} alt="check" /> <span>{item.name}</span>
										</>
									)}
								</button>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

Media.REMOTE_LINK_MODE = REMOTE_LINK_MODE;
Media.UPLOADED_FILE_MODE = UPLOADED_FILE_MODE;

Media.IMAGE_FILE_TYPE = IMAGE_FILE_TYPE;
Media.VIDEO_FILE_TYPE = VIDEO_FILE_TYPE;
Media.AUDIO_FILE_TYPE = AUDIO_FILE_TYPE;

Media.defaultProps = {
	className: '',
	fileType: IMAGE_FILE_TYPE,
	sizeLimit: 1 * 1024 * 1024,
	videoSizeLimit: 5 * 1024 * 1024,
	preview: '',
	name: '',
	placeholder: '',
	disabled: false,
	onlyFileUpload: false,
	onSetFileSize: () => {},
	onShowSizeLimitPopup: () => {},
};

const mapState = (state, ownProps) => {
	const form = _.get(state, `form.${ownProps.meta.form}.values`, {});

	return {
		preview:
			_.get(form, `${ownProps.input.name}.selected`) === Media.UPLOADED_FILE_MODE
				? _.get(form, `${ownProps.input.name}.file.url`)
				: _.get(form, `${ownProps.input.name}.remoteURL`),
		name:
			_.get(form, `${ownProps.input.name}.selected`) === Media.UPLOADED_FILE_MODE
				? _.last(_.get(form, `${ownProps.input.name}.file.name`, '').split('/'))
				: _.last(_.get(form, `${ownProps.input.name}.remoteURL`, '').split('/')),
	};
};

const mapDispatch = dispatch => ({
	uploadMedia: file => dispatch(actions.uploadMedia(file)),
	changeField: (formName, fieldName, value) => dispatch(rrChange(formName, fieldName, value)),
});

export default connect(mapState, mapDispatch)(Media);
