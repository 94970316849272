/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-danger */
// @flow
import * as React from 'react';
import cn from 'classnames';
import { Text } from 'client/components/common/Text';
import * as css from './Footer.scss';

type Props = {
	className?: string,
	activeScreen: number,
};

const Footer = ({ className, activeScreen }: Props) => {
	const isFirstScreen = activeScreen === 0;
	return (
		<footer className={cn(css.footer, className)}>
			<Text
				tag="span"
				weight="light"
				size="S16"
				className={cn(css.firstRow, isFirstScreen ? css.active : css.hidden)}
			>
				*בתהליך ההרשמה יוצגו לך הסברים אודות השירות. בפרט בדיקת קמפיין לאישור תימשך עד חמישה (5) ימי עבודה
				והשירות כפוף להוראות התקנון המלא בלבד ומתאים לעסקים שברשותם חומרים פרסומיים דיגיטליים. מדובר בגרסת בטא.
			</Text>
			<div className={css.secondRow}>
				<Text tag="span">תקנון השירות</Text>
				<Text tag="span">מדיניות פרטיות</Text>
				<Text tag="span">
					לעזרה ופרטים נוספים או לביטול עסקה כדין <a href="https://google.com">לחצו כאן</a> או בפקס:
					03-6932466, טלפון: 03-6932424, מייל:{' '}
					<a href="mailto:sales.ynet@ynet.co.il">sales.ynet@ynet.co.il</a>, כתובת: ידיעות אינטרנט ת.ד 1109
					ראשל"צ 7565233
				</Text>
			</div>
		</footer>
	);
};

Footer.defaultProps = {
	className: '',
};

export { Footer };
