/* eslint-disable no-nested-ternary */
// @flow

/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { change as rrChange } from 'redux-form';
import classNames from 'classnames';
import { notification } from 'antd';
import _ from 'lodash';

import * as actions from 'client/actions';
import { CAN_USE_DOM } from 'common/constants';
import { FILE_DEFAULT } from 'admin/constants';

import uploadIcon from 'assets/client/images/firstScreen/upload.png';
import checkIcon from 'assets/client/images/createLeadPopUp/check.png';

import * as css from './MediaField.scss';

const REMOTE_LINK_MODE = 'remoteURL';
const UPLOADED_FILE_MODE = 'file';

const IMAGE_FILE_TYPE = 'IMAGE_FILE_TYPE';
const VIDEO_FILE_TYPE = 'VIDEO_FILE_TYPE';
const AUDIO_FILE_TYPE = 'AUDIO_FILE_TYPE';

type Props = {
	className?: string,
	fileType?: string,
	sizeLimit?: number,
	videoSizeLimit?: number,
	preview?: string,
	name?: string,
	input: ReduxFormInput,
	meta: ReduxFormMeta,
	placeholder?: any,
	disabled?: boolean,
	onlyFileUpload?: boolean,
	changeField: Function,
	uploadMedia: Function,
	onSetFileSize?: Function,
	onShowSizeLimitPopup?: Function,
};

const Media = ({
	className,
	placeholder,
	onlyFileUpload,
	name,
	input,
	meta,
	preview,
	disabled,
	fileType,
	sizeLimit,
	videoSizeLimit,
	uploadMedia,
	changeField,
	onSetFileSize,
	onShowSizeLimitPopup,
}: Props) => {
	const [isActive, setIsActive] = useState(false);
	const [isInProgress, setIsInProgress] = useState(false);
	const [outOfLimitSize, setOutOfLimitSize] = useState(false);

	const containerRef = useRef<?HTMLDivElement>(null);
	const id = `${meta.form}${input.name}`;
	const fileSizeErrorMessage = `יש לצרף תמונה בגודל של עד 5 מגה`;

	const onClearClick = () => {
		changeField(meta.form, input.name, FILE_DEFAULT);
	};

	const onFileChange = async e => {
		setOutOfLimitSize(false);
		const file = e.target.files[0];

		const limitToUse = (file.type.includes('video') ? videoSizeLimit : sizeLimit) || 0;
		const sizeLimitError = `File exceeded size limit of ${limitToUse / (1024 * 1024)} Mb`;
		const outLimit = file.size >= limitToUse;

		if (outLimit) {
			// notification.error({ message: 'Error!', description: sizeLimitError });
			console.warn(`${sizeLimitError}\nFile size:${file.size}`);
			setTimeout(() => setOutOfLimitSize(true), 500);

			return;
		}

		setIsInProgress(true);

		try {
			const result = await uploadMedia(file);
			if (onSetFileSize) onSetFileSize(file.size);

			const val = {
				...FILE_DEFAULT,
				file: result,
				selected: UPLOADED_FILE_MODE,
			};

			changeField(meta.form, input.name, val);
		} catch (error) {
			notification.error({
				message: 'Error!',
				description: error.message || 'Something went wrong while uploading file',
			});
			console.error(error);
		}
		setIsInProgress(false);
	};

	const onDocumentClickListener = (e: MouseEvent) => {
		const target: ?Node = (e.target: any);
		if (containerRef.current && containerRef.current.contains(target)) {
			return;
		}

		setIsActive(false);
	};

	useLayoutEffect(() => {
		if (CAN_USE_DOM) {
			document.addEventListener('click', onDocumentClickListener);
		}
		return () => {
			if (CAN_USE_DOM) {
				document.removeEventListener('click', onDocumentClickListener);
			}
		};
	});

	return (
		<div
			className={classNames(css.mediaField, className, {
				[css.active]: isActive,
				[css.inProgress]: isInProgress,
				[css.disabled]: disabled,
			})}
			ref={containerRef}
		>
			<div className={classNames(css.fileOutOfLimit, outOfLimitSize && css.fileOutOfLimitActive)}>
				{fileSizeErrorMessage}
			</div>
			<label className={classNames(css.controlLabel)} htmlFor={id}>
				<div className={css.fileNameWrapper}>
					<div className={css.text}>{name}</div>
					<div className={css.buttonPlus}>
						<img src={uploadIcon} alt="Upload" />
					</div>
				</div>
				<div className={css.placeholder}>{placeholder}</div>
				<input
					id={id}
					type="file"
					onChange={onFileChange}
					onClick={e => {
						e.target.value = '';
					}}
					accept={
						fileType === IMAGE_FILE_TYPE ? 'image/*' : fileType === VIDEO_FILE_TYPE ? 'video/*' : 'audio/*'
					}
				/>
			</label>
			<div className={css.uploadedFiles}>
				<button className={css.file} type="button" onClick={() => onClearClick()}>
					{preview && name && (
						<>
							<img src={checkIcon} alt="check" /> <span>{name}</span>
						</>
					)}
				</button>
			</div>
		</div>
	);
};

Media.REMOTE_LINK_MODE = REMOTE_LINK_MODE;
Media.UPLOADED_FILE_MODE = UPLOADED_FILE_MODE;

Media.IMAGE_FILE_TYPE = IMAGE_FILE_TYPE;
Media.VIDEO_FILE_TYPE = VIDEO_FILE_TYPE;
Media.AUDIO_FILE_TYPE = AUDIO_FILE_TYPE;

Media.defaultProps = {
	className: '',
	fileType: IMAGE_FILE_TYPE,
	sizeLimit: 10 * 1024 * 1024,
	videoSizeLimit: 50 * 1024 * 1024,
	preview: '',
	name: '',
	placeholder: '',
	disabled: false,
	onlyFileUpload: false,
	onSetFileSize: () => {},
	onShowSizeLimitPopup: () => {},
};

const mapState = (state, ownProps) => {
	const form = _.get(state, `form.${ownProps.meta.form}.values`, {});

	return {
		preview:
			_.get(form, `${ownProps.input.name}.selected`) === Media.UPLOADED_FILE_MODE
				? _.get(form, `${ownProps.input.name}.file.url`)
				: _.get(form, `${ownProps.input.name}.remoteURL`),
		name:
			_.get(form, `${ownProps.input.name}.selected`) === Media.UPLOADED_FILE_MODE
				? _.last(_.get(form, `${ownProps.input.name}.file.name`, '').split('/'))
				: _.last(_.get(form, `${ownProps.input.name}.remoteURL`, '').split('/')),
	};
};

const mapDispatch = dispatch => ({
	uploadMedia: file => dispatch(actions.uploadMedia(file)),
	changeField: (formName, fieldName, value) => dispatch(rrChange(formName, fieldName, value)),
});

export default connect(mapState, mapDispatch)(Media);
