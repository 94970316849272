// @flow

/* eslint-disable max-len,react/jsx-tag-spacing */
import * as React from 'react';
import classNames from 'classnames';
// import { Responsive } from '../Responsive';
import * as css from './Icon.scss';

type Props = {|
	type: string,
	className?: string,
	width?: number,
	height?: number,
	color?: string,
|};

/**
 * @link - https://material.io/tools/icons/?style=baseline
 * @return {XML}
 */
const Icon = ({ type, className, width, height, color, ...iconProps }: Props) => {
	let icon = null;
	const svgProps = {
		width,
		height,
		viewBox: `0 0 ${width || 24} ${height || 24}`,
		xmlns: 'http://www.w3.org/2000/svg',
	};
	// const isDesktop = Responsive.isMatching(Responsive.DESKTOP);

	switch (type) {
		case 'whatsapp':
			icon = (
				<svg {...svgProps}>
					<g fill="none" fillRule="evenodd">
						<circle fill="#313131" cx="16" cy="16" r="16" />
						<path d="M6 6h20v20H6z" />
						<path
							d="m8.5 23.5 1.375-3.167a7.5 7.5 0 1 1 2.833 2.417L8.5 23.5"
							stroke="#FFF"
							strokeWidth="1.8"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M13.5 14.333a.417.417 0 1 0 .833 0V13.5a.417.417 0 1 0-.833 0v.833a4.167 4.167 0 0 0 4.167 4.167h.833a.417.417 0 0 0 0-.833h-.833a.417.417 0 1 0 0 .833"
							stroke="#FFF"
							strokeWidth="1.25"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</g>
				</svg>
			);
			break;
		case 'facebook':
			icon = (
				<svg {...svgProps}>
					<g fill="none" fillRule="evenodd">
						<circle fill="#313131" cx="16" cy="16" r="16" />
						<path d="M6 6h20v20H6z" />
						<path
							d="M11.833 18.667h2.5V25.5h3.334v-6.833h2.5L21 15.333h-3.333v-2.666c0-.46.373-.834.833-.834H21V8.5h-2.5a4.167 4.167 0 0 0-4.167 4.167v2.666h-2.5v3.334z"
							stroke="#FFF"
							strokeWidth=".5"
							fill="#FFF"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</g>
				</svg>
			);
			break;
		case 'ynet-logo':
			icon = (
				<svg {...svgProps}>
					<defs>
						<path
							d="M0 19c0 10.494 8.506 19 19 19 10.493 0 19-8.506 19-19S29.493 0 19 0C8.506 0 0 8.506 0 19z"
							id="uqt0onkaca"
						/>
					</defs>
					<g fill="none" fillRule="evenodd">
						<path
							d="M59 29.965V13.88C59 10.352 56.78 8 53.312 8c-2.404 0-4.347.94-6.289 3.246l-.462-2.117c-.14-.564-.463-.847-1.11-.847h-1.387c-.555 0-1.064.377-1.064.988v20.695c0 .565.509 1.035 1.064 1.035h2.127c.555 0 1.017-.47 1.017-1.035V17.22c0-3.152 2.173-5.55 4.948-5.55 1.896 0 2.636.94 2.636 3.151v15.145c0 .565.508 1.035 1.064 1.035h2.127c.555 0 1.017-.47 1.017-1.035M66.425 17.405c.465-4.842 2.608-6.006 4.285-6.006 2.887 0 3.865 3.026 3.959 6.006h-8.244zM79 19.547C79 11.027 75.274 8 70.57 8 65.4 8 62 12.423 62 19.407 62 27.042 65.586 31 71.92 31c2.749 0 4.332-.42 5.776-1.07.559-.234.792-.7.605-1.258l-.512-1.49c-.186-.512-.559-.885-1.258-.652-1.35.42-2.608.792-4.377.792-4.1 0-5.543-2.421-5.73-6.751h11.551c.56 0 1.025-.466 1.025-1.024zM90.974 29.437l-.232-1.425c-.093-.551-.557-.827-1.067-.735-.418.092-1.068.184-1.44.184-1.067 0-1.577-.552-1.577-1.793V11.833h2.645c.557 0 .975-.46.975-1.011V9.58c0-.552-.418-1.012-.975-1.012h-2.645V2.78c0-.828-.604-.874-1.3-.69l-2.088.597c-.51.138-.79.598-.79 1.195v4.688h-2.505c-.604 0-.975.46-.975 1.012v1.24c0 .552.371 1.012.975 1.012h2.506v14.525c0 3.08 1.531 4.642 4.826 4.642 1.114 0 2.228-.138 2.97-.368.65-.184.79-.643.697-1.195"
							fill="#000"
						/>
						<path d="M0 19C0 8.506 8.506 0 19 0s19 8.506 19 19-8.506 19-19 19S0 29.494 0 19" fill="red" />
						<g>
							<mask id="70rqr899xb" fill="#fff">
								<use xlinkHref="#uqt0onkaca" />
							</mask>
							<path
								d="M27.038 8.348c.153-.614-.192-.998-.69-.998h-2.303c-.5 0-.921.269-1.036.806l-2.84 11.588c-.268 1.074-.46 2.034-.614 2.724a11.884 11.884 0 0 0-.345-2.34L15.987 8.194c-.154-.537-.537-.844-1.075-.844H12.38c-.499 0-.882.384-.69.96l4.91 15.386c.193.576.461.806 1.037.806h1.304c-.076.384-.46 2.072-.652 2.302-.46 1.612-1.266 2.725-2.61 3.415l-.536.269c-.422.23-.691.767-.46 1.19l.805 1.573c.23.46.73.613 1.151.422l.844-.384c2.034-.921 3.53-2.686 4.298-5.526l5.257-19.415z"
								fill="#FFF"
								mask="url(#70rqr899xb)"
							/>
						</g>
					</g>
				</svg>
			);
			break;

		case 'share':
			icon = (
				<svg {...svgProps}>
					<g>
						<rect data-name="Rectangle 299" width={width} height={height} rx="17.5" fill="#000" />
						<g data-name="Group 366">
							<g data-name="Group 365">
								<g data-name="Group 364">
									<path
										data-name="Path 1246"
										d="M5.8 6.858a2.977 2.977 0 0 1 0 1.667l5.471 2.9a2.358 2.358 0 1 1-.554 1.046l-5.471-2.9a2.958 2.958 0 1 1 0-3.758l5.471-2.9a2.368 2.368 0 1 1 .554 1.046z"
										fill="#fff"
										transform="translate(8.875 10.057)"
									/>
								</g>
							</g>
						</g>
					</g>
				</svg>
			);
			break;
		case 'accessibility':
			icon = (
				<svg {...svgProps}>
					<g data-name="Group 103">
						<path
							data-name="Path 19"
							d="M0 0h32.334v32.334H0z"
							transform="translate(4.862 4.864)"
							style={{ fill: 'none' }}
						/>
						<path
							data-name="Path 20"
							d="M18.126 4a2.694 2.694 0 1 1-2.695 2.694A2.694 2.694 0 0 1 18.126 4zm12.126 9.431h-8.084v17.514h-2.695v-8.084h-2.695v8.083h-2.694V13.431H6v-2.695h24.251z"
							transform="translate(2.905 3.559)"
							style={{ fill: '#fff' }}
						/>
					</g>
					<path
						data-name="Ellipse 1"
						d="M21.031 3.186A17.844 17.844 0 0 0 8.413 33.648 17.844 17.844 0 1 0 33.648 8.413a17.727 17.727 0 0 0-12.617-5.227m0-3.186A21.03 21.03 0 1 1 0 21.03 21.031 21.031 0 0 1 21.031 0z"
						style={{ fill: '#fff' }}
					/>
				</svg>
			);
			break;
		default:
			break;
	}

	return (
		<i className={classNames(css.icon, className)} {...iconProps}>
			{icon}
		</i>
	);
};

Icon.defaultProps = {
	className: '',
	width: 24,
	height: 24,
	color: '#111',
};

export { Icon };
