// @flow
import React, { useState } from 'react';
import Slider from 'rc-slider';
import cn from 'classnames';
import _ from 'lodash';
import { Text } from 'client/components/common/Text';
import css from './RangeSlider.scss';

type Props = {
	input: {
		onChange: Function,
	},
	min: number,
	max: number,
	step: number,
	className?: string,
};

export default function RangeSlider(props: Props) {
	const { min, max, step, className } = props;
	const [values, setValues] = useState(3000);

	const { Handle } = Slider;
	const handle = handleProps => {
		const { dragging } = handleProps;
		return (
			<Handle {...handleProps} dragging={dragging.toString()} className={css.handle}>
				<div className={css.tooltip}>
					<Text type="S24">{values} ₪</Text>
				</div>
				<div className={css.handleDot}></div>
			</Handle>
		);
	};

	const onChangeSlider = e => {
		_.invoke(props, 'input.onChange', e);
		setValues(e);
	};

	return (
		<div className={css.rangeWrap}>
			<Slider
				min={min}
				max={max}
				step={step}
				onChange={e => {
					onChangeSlider(e);
				}}
				handle={handleProps => handle(handleProps)}
				className={cn(css.rangeSlider, className)}
			/>
			<div className={css.minMaxBottom}>
				<Text type="S18" weight="light">
					{max} ₪
				</Text>
				<Text type="S18" weight="light">
					{min} ₪
				</Text>
			</div>
		</div>
	);
}

RangeSlider.defaultProps = {
	className: '',
};
