// @flow
import * as actions from 'client/constants/actions';
import * as api from 'api';
import { GET_ITEMS_DEFAULT_PARAMS } from 'admin/constants';

import { apiRequestAction } from './helpers/api-request';

export { uploadMedia, deleteMedia } from './media';
export * from './auth';

export function createItem<I: AbstractItem>(params: CreateItemParams<I>): any {
	return async (dispatch: Dispatch) => {
		const requestAction = apiRequestAction({
			action: actions.CREATE_ITEM,
			request: api.createItemLead,
			params,
		});

		return dispatch(requestAction);
	};
}

export function getItems(params: GetItemsParams<>): any {
	return async (dispatch: Dispatch) => {
		const requestAction = apiRequestAction({
			action: actions.GET_ITEMS,
			request: api.getItems,
			params: { ...GET_ITEMS_DEFAULT_PARAMS, ...params },
		});

		return dispatch(requestAction);
	};
}
export function getItem<I: AbstractItem>(params: CreateItemParams<I>): any {
	return async (dispatch: Dispatch) => {
		const requestAction = apiRequestAction({
			action: actions.GET_ITEM,
			request: api.getItem,
			params,
		});

		return dispatch(requestAction);
	};
}

export function updateRealTimeDataBase(params: any): any {
	return async (dispatch: Dispatch) => {
		const requestAction = apiRequestAction({
			action: actions.UPDATE_ITEM_REAL_TIME,
			request: api.updateRealTimeDataBase,
			params,
		});

		return dispatch(requestAction);
	};
}
