// @flow
/* eslint-disable no-nested-ternary, react/no-danger */
import React, { useRef } from 'react';
import cn from 'classnames';
import { formatLink } from 'common/utils';
import SocialsForItem from 'client/components/common/SocialsForItem';
import { Responsive } from 'client/components/common';

import Slider from 'react-slick';
import _ from 'lodash';
// import { fileLink } from 'common/utils';

import defaultCandle from 'assets/client/images/firstScreen/final_candel.png';
import css from './FullWidthItem.scss';

type Props = {
	item: LeadInfo,
};

export default function FullWidthItem(props: Props) {
	const { item } = props;
	const sliderRef: any = useRef();

	const imageParams = `?auto=compress&auto=format&&fit=facearea&facepad=10&faces=1`;
	const isMobile = Responsive.isMatching(Responsive.MOBILE);
	const isSliderUse = !_.isEmpty(item.profilePictures) && _.size(item.profilePictures) !== 0;
	const PrevArrow = () => {
		const onClickPrev = () => sliderRef.current.slickNext();

		return (
			<div className={cn(css.innerSlideArrow, css.prevArrow)} onClick={onClickPrev}>
				<span className={css.firstArrowLine}></span>
				<span className={css.secondArrowLine}></span>
			</div>
		);
	};
	const NextArrow = () => {
		const onClickNext = () => sliderRef.current.slickPrev();

		return (
			<div className={cn(css.innerSlideArrow, css.nextArrow)} onClick={onClickNext}>
				<span className={css.firstArrowLine}></span>
				<span className={css.secondArrowLine}></span>
			</div>
		);
	};
	const innerSliderSettings = {
		prevArrow: <PrevArrow />,
		nextArrow: <NextArrow />,
		dots: true,
		autoplay: true,
		autoplaySpeed: 5000,
	};

	return (
		<div className={cn(css.fullWidthItem)}>
			<div className={css.popUpWindow}>
				<div className={css.candleWrapper}>
					<div className={css.leftPart}>
						<div className={css.topBlock}>
							<h1 className={css.name}>{item.nameOfTheCandle} ז”ל</h1>
							<div className={css.year}>
								{(item?.birthYear || item?.deathYear) && (
									<div>
										{item.birthYear ? item.birthYear : '?'}-{item.deathYear ? item.deathYear : '?'}
									</div>
								)}
							</div>
						</div>
						<div className={css.descriptionBlock}>
							<p className={css.senderName}>{item.senderName ? <>מאת {item.senderName}</> : ''}</p>
							{item.freeText && (
								<p className={css.freeText} dangerouslySetInnerHTML={{ __html: item?.freeText }} />
							)}
						</div>
						{!isMobile && <SocialsForItem item={item} isFromFullWidthItem />}
					</div>
					<div className={cn(css.rightPart, !isSliderUse && css.withOverflow)}>
						{!_.isEmpty(item.profilePictures) && _.size(item.profilePictures) !== 0 ? (
							<Slider className={css.slider} {...innerSliderSettings} ref={sliderRef}>
								{_.map(
									item.mainPicture.file
										? [item.mainPicture.file, ...item?.profilePictures]
										: [...item?.profilePictures],
									(picture: any, index: any) => {
										return (
											picture.url &&
											(picture.name.includes('videos') ? (
												<video
													src={picture.url}
													muted
													autoPlay
													controls
													key={picture.name}
												></video>
											) : (
												<div key={`${picture.name}-${index}`}>
													<img
														src={formatLink(picture.name, '0', imageParams)}
														alt=""
														className={css.candlePicture}
													/>
												</div>
											))
										);
									}
								)}
							</Slider>
						) : item?.mainPicture?.file?.url ? (
							<img
								src={formatLink(item?.mainPicture.file.name, '0', imageParams)}
								alt=""
								className={css.defaultCandleImg}
							/>
						) : (
							<img src={defaultCandle} alt="" className={css.defaultCandleImg} />
						)}
					</div>
					{isMobile && <SocialsForItem item={item} isFromFullWidthItem />}
				</div>
			</div>
		</div>
	);
}
