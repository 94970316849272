// @flow

import * as React from 'react';
import cn from 'classnames';
// import _ from 'lodash';

import { share } from 'client/utils/share';
import { IS_DEVEL } from 'common/constants';
// import { formatLink } from 'common/utils';
// import { shareImage } from 'common/constants/seo';
import { dataLayerEvents } from 'common/utils/gtag';
import { SHARE_CONSTANTS } from 'client/constants';
// import { Responsive } from 'client/components/common';
import whatsAppIcon from 'assets/client/images/socialsForItem/whatsapp-black.png';
import facebookIcon from 'assets/client/images/socialsForItem/facebook-black.png';
// import instaIcon from 'assets/client/images/socialsForItem/instagram-black.png';
// import { Icon } from '../Icon';
import * as css from './SocialsForItem.scss';

type Props = {
	className?: string,
	item: LeadInfo,
	isFromFullWidthItem: boolean,
};

type State = {
	isOpen: boolean,
};

type ReactObjRef = { current: HTMLDivElement | null };
export default class Socials extends React.Component<Props, State> {
	sociallsRef: ReactObjRef;
	static defaultProps = {
		className: '',
	};

	state = {
		isOpen: false,
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			isOpen: false,
		};
		this.sociallsRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener('click', this.documentClickListener);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.documentClickListener);
	}

	documentClickListener = (e: MouseEvent) => {
		if (this.sociallsRef.current) {
			if (this.sociallsRef.current.contains((e.target: any))) return false;
		}

		this.setState({
			isOpen: false,
		});
	};

	toggleOpenMenu = (e: SyntheticEvent<HTMLDivElement>) => {
		this.setState(prevState => ({
			isOpen: !prevState.isOpen,
		}));
	};

	onShare = (e: SyntheticEvent<HTMLButtonElement>) => {
		const { isFromFullWidthItem, item } = this.props;
		const type = e.currentTarget.dataset.id;
		// const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
		// events.onShareClick(type, this.props.shareCategory);
		if (!IS_DEVEL) {
			dataLayerEvents.onSharePersonalBtnClick(type);
			// const imageParamsOg = `?w=200&h=200&fit=crop`;
			// const ogImage = formatLink(_.get(this.props.item, 'mainPicture.file.name', ''), '0', imageParamsOg);
			// const options = {
			// 	url: `item/${this.props.item.id}`,
			// 	itemTitle: this.props.item.nameOfTheCandle,
			// 	itemDescription: this.props.item.freeText,
			// 	itemImage: ogImage || shareImage,
			// };

			// if (isDesktop) {
			if (isFromFullWidthItem) {
				share(type, { url: `?p=${item.id}` });
			} else {
				share(type);
			}

			// } else {
			// 	share(type);
		}
		// }
	};

	render() {
		const { className } = this.props;

		return (
			<div className={cn(css.socials, this.state.isOpen && css.open, className)} ref={this.sociallsRef}>
				<p className={css.text}>שתפו את הסיפור</p>

				<div className={css.overflowWrap}>
					<button
						type="button"
						className={css.socialButton}
						data-id={SHARE_CONSTANTS.WHATSAPP}
						onClick={this.onShare}
					>
						<img src={whatsAppIcon} alt="whatsapp" className={cn(css.iconWhatsapp, css.icon)} />
					</button>
					{/* <button
						className={css.socialButton}
						type="button"
						data-id={SHARE_CONSTANTS.FACEBOOK}
						onClick={this.onShare}
					>
						<img src={instaIcon} alt="instagram" className={cn(css.iconInsta, css.icon)} />
					</button> */}
					<button
						className={css.socialButton}
						type="button"
						data-id={SHARE_CONSTANTS.FACEBOOK}
						onClick={this.onShare}
					>
						<img src={facebookIcon} alt="facebook" className={cn(css.iconWhatsapp, css.icon)} />
					</button>
				</div>
			</div>
		);
	}
}
