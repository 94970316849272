const canonicalURl = 'https://memorialday2023.ynet.co.il';
// Seo title and descirption
export const seoTitle = 'החלל שלי 2023 – ynet';
export const seoDescription =
	'יום הזיכרון לחללי מערכות ישראל 2023 תשפ"ג.  ביום הזיכרון ישראל עומדת מלכת כדי לתת מקום למי שאיננו';
// Share varialbles
export const shareUrl = 'https://memorialday2023.ynet.co.il/';
export const shareTitle = 'החלל שלי 2023 – ynet';
export const shareDescription =
	'יום הזיכרון לחללי מערכות ישראל 2023 תשפ"ג.  ביום הזיכרון ישראל עומדת מלכת כדי לתת מקום למי שאיננו';

//! Attention: do not import og images as variable, write path here:
export const shareImage = `${canonicalURl}/assets/client/share1200x630.png`;
