// @flow
import * as React from 'react';
import { Field } from 'redux-form';

import Input from './RangeSlider';

type Props = {
	className?: string,
	name: string,
	placeholder?: any,
	min: number,
	max: number,
	step: number,
};

export default class RangeSlider extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
		placeholder: '',
	};

	render() {
		const { className, name, min, max, step } = this.props;
		const InputComponent = props => <Input min={min} max={max} step={step} {...props} className={className} />;

		return <Field name={name} component={InputComponent} />;
	}
}
