// @flow
import React, { useState } from 'react';
import ReactSelect from 'react-select';
import _ from 'lodash';
import { change as rrChange } from 'redux-form';
import { connect } from 'react-redux';
import css from './ReactSelect.scss';

type Props = {
	placeholder?: string,
	options: Array<any>,
	meta: ReduxFormMeta,
	input: ReduxFormInput,
	name: string,
	changeField: Function,
};
function Select(props: Props) {
	const { placeholder, name, meta, input, changeField, options } = props;
	const [selectedOption, setSelectedOption] = useState(null);
	const [valueSelect, setValueSelect] = useState({ label: '' });
	const customStyles = {
		option: (provided, { isSelected }) => ({
			...provided,
			background: isSelected ? 'rgb(214, 214, 214)' : '#fff',
			color: 'rgb(95, 95, 95)',
		}),
		// control: () => ({
		// 	// none of react-select's styles are passed to <Control />
		// 	// width: 200,
		// 	height: '50px',
		// 	display: 'flex',
		// 	background: '#fff',
		// }),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';

			return { ...provided, opacity, transition };
		},
	};

	return (
		<ReactSelect
			name={name}
			onChange={(e: any) => {
				_.invoke(props, 'input.onChange', e);
			}}
			// onBlur={(e: any) => _.invoke(props, 'input.onBlur', e)}
			options={options}
			value={input.value}
			placeholder={placeholder}
			styles={customStyles}
			classNamePrefix="react-select"
			className={css.selectC}
		/>
	);
}

Select.defaultProps = {
	placeholder: '',
};

const mapState = (state, ownProps) => {
	return {};
};
const mapDispatch = dispatch => ({
	// uploadMedia: file => dispatch(actions.uploadMedia(file)),
	changeField: (formName, fieldName, value) => dispatch(rrChange(formName, fieldName, value)),
});

export default connect(mapState, mapDispatch)(Select);
